/*
 * @Description: 总数统计
 * @Author: 琢磨先生
 * @Date: 2022-06-20 00:19:25
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-06-20 00:20:01
 */

import http from "./axios_init";

export default {
  /**
   * 总数统计
   * @returns
   */
  get_count() {
    return http.get("seller/v1/count");
  },
};
